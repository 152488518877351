import React, { Component } from 'react';
import {Label,Card,CardBody,Col,Row,Button, CardHeader, FormGroup, CardFooter} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import Modal from "react-modal";
import InformationRequest from '../Elements/InformationRequest';
import TimelineHome from '../Elements/TimelineHome';
import { adminLogin } from "../../actions/index";
import investmentImage from '../../assets/images/investment.png';
import registrationImage from '../../assets/images/registration.png';
import infoGraph from '../../assets/images/infograph1.png';
import getProfit from '../../assets/images/get-profit.png';
import readings from '../../assets/images/readings.jpg';
import logo from '../../assets/images/logo/logo.png';
import newsletterIcon from '../../assets/images/newsletterIcon.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import DateFormatMonthName from "../../constants/dateformatmonthname";
/*import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();*/
const MAX_LENGTH = 140;
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    transform             : 'translate(-50%, -50%)',
    width        		  : '90%',
	maxWidth			  : '600px'
  }
};
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      subscribeModalOpen : false,
      subscribeEmail: "",
      message: "",
      banners: "",
      readings: "",
      tabActive1: "active",
      tabActive2: "",
      tabActive3: "",
      investmentPhiloscphy: "",
      profileDataForStore: {},
      responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            768: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
    };
    this.updateActiveToggleClass = this.updateActiveToggleClass.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.sendSubscription(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  openModal(){
    this.setState({subscribeModalOpen:true});
  }

  closeModal(){
    this.setState({subscribeModalOpen:false});
  }

  updateActiveToggleClass = (e) => {
    var tc = e.target.value;
    if(tc === 1){
      this.setState({
        tabActive1 : 'active',
        tabActive2 : '',
        tabActive3 : ''
      })
    }
    if(tc === 2){
      this.setState({
        tabActive1 : '',
        tabActive2 : 'active',
        tabActive3 : ''
      })
    }
    if(tc === 3){
      this.setState({
        tabActive1 : '',
        tabActive2 : '',
        tabActive3 : 'active'
      })
    }
  }

  async sendSubscription(postData) {
    let path = apiPath.sendSubscription;
    this.setState({ loading: true });
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({subscribeModalOpen:false,subscribeEmail:''});
          this.closeModal();
          this.setState({ message: resultJson.msg});
          this.generate('success');
          /*setTimeout(() => {
            this.setState({ redirectTo: true });
          }, 1500);*/
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async getHomeData() {
    let path = apiPath.home;
    this.setState({loading: true});
    let options = {
      method: 'GET',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
    }
    let result = await Http(path, options);
    this.setState({loading: false});
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({
            banners: resultUsersJson.banners,
            readings: resultUsersJson.insights,
          });
          await this.getStaticPage();
          this.generate('success');

        } else {
          this.generate('danger');
          this.setState({message: resultJson.msg});
          setTimeout(() => {
            this.setState({message: null});
          }, 5000);
        }
      }
    } else {
      this.generate('danger');
      this.setState({message: result.statusText});
      setTimeout(() => {
        this.setState({message: null});
      }, 5000);
    }
  }

  async getStaticPage(){
    let path = apiPath.getStaticPage;
    this.setState({ loading: true });
    let postData = {};
    postData.slug = "our_client centric approach";
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let investmentPhiloscphy = resultJson.result;
          this.setState({ investmentPhiloscphy: investmentPhiloscphy});
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentWillMount(){
    localStorage.removeItem('oneTimeModelOpen')
  }

  componentDidMount(){
    this.getHomeData();
  }

  bannersList(){
    if(!_.isEmpty(this.state.banners)){
      return this.state.banners.map((value,index)=>{
        return <div className="item" key={index}>
          <div className="bg_img" style={{backgroundImage: `url(${value.photo})`}}>
            <div className="container">
              <div className="hero-section">
                <div className="hero-content wow slideInLeft">
                <h6 className="cate">{value.title}</h6>
                <h1 className="title" dangerouslySetInnerHTML={{__html : value.sub_title}}></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      })
    }
  }

  readingsList(){
    if(!_.isEmpty(this.state.readings)){
      return this.state.readings.map((value,index)=>{
        return <div className="card" key={index}>
          <h6 className="title"><a href={"/insight-what-vallum-reads/"+value._id}>{value.title}</a></h6>
          {/*<div className="submit-date"><i className="fa fa-clock-o"></i> <DateFormatMonthName date={value.createdAt} /> </div>*/}
        </div>
      })
    }
  }

  render() {
    if(this.state.redirectTo){
      return <Redirect to="/" />
    }
    Modal.setAppElement("#root");
    return (
      <div>
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Slider Section */}
          <section className="hero-section-main">
            {this.message()}
            <OwlCarousel className='owl-theme hero-section-slider' items={1} loop margin={0} nav responsiveClass={true} dots={false} dotsSpeed={1000} dragEndSpeed={1000}  animateIn='fadeIn' animateOut='fadeOut' smartSpeed={450} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={false}>
              {this.bannersList()}
            </OwlCarousel>
          </section>
        {/* Slider Section */}

        {/* Feature-Section */}
          <section className="feature-section padding-top" id="feature">
              <div className="container">
                  <div className="row justify-content-center feature-wrapper">
                      <div className="col-md-10 col-sm-10 col-lg-8">
                          <div className="feature-item">
                              <div className="feature-thumb">
                                 <img src={investmentImage} alt="feature" />
                              </div>
                              <div className="feature-content">
                                <h5 className="title">{this.state.investmentPhiloscphy.title ? this.state.investmentPhiloscphy.title : "Investment Philosophy"}</h5>
                                <p dangerouslySetInnerHTML={{__html : `${this.state.investmentPhiloscphy.content ? this.state.investmentPhiloscphy.content.substring(0, MAX_LENGTH) : ''}...`}}></p>
                                <a href="/our-client-centric-approach" className="custom-button">Read More</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        {/* Feature-Section */}

        {/* Timeline Section */}
          <TimelineHome />
        {/* Timeline Section */}

        {/* How-Section */}
          <section className="get-section padding-top padding-bottom">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-md-10 col-lg-8">
                          <div className="section-header">
                              {/*<span className="cate">get to know</span>*/}
                              <h2 className="title"> Begin Your Investing Journey Now!</h2>
                              <p>
                                  Follow these simple steps to start investing with us…
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="hover-tab">
                      <div className="row justify-content-center">
                          <div className="col-lg-6 d-lg-block d-none">
                              <div className="hover-tab-area">
                                  <div className="tab-area">
                                      <div className={"tab-item first "+this.state.tabActive1}>
                                          <img src={registrationImage} alt="info"/>
                                      </div>
                                      <div className={"tab-item second "+this.state.tabActive2}>
                                         <img src={infoGraph} alt="info"/>
                                      </div>
                                      <div className={"tab-item third "+this.state.tabActive3}>
                                         <img src={getProfit} alt="info"/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                              <div className="hover-tab-menu">
                                  <ul className="tab-menu">
                                      <li className={this.state.tabActive1} onMouseOver={this.updateActiveToggleClass} value="1">
                                          <div className="menu-thumb">
                                              <span> 1 </span>
                                          </div>
                                          <div className="menu-content">
                                              <h5 className="title">Instant  registration</h5>
                                              <p>
                                                  Click on <a href="/sign-up"><u>Sign Up</u></a>  to begin your on-boarding journey.
                                              </p>
                                          </div>
                                      </li>
                                      <li className={this.state.tabActive2} onMouseOver={this.updateActiveToggleClass} value="2">
                                          <div className="menu-thumb">
                                              <span>2</span>
                                          </div>
                                          <div className="menu-content">
                                              <h5 className="title">VALLUM TRACKER</h5>
                                               <p>
                                                  Use our tech enabled platform to complete all documentation processes.
                                              </p>
                                          </div>
                                      </li>
                                      <li className={this.state.tabActive3} onMouseOver={this.updateActiveToggleClass} value="3">
                                          <div className="menu-thumb">
                                              <span>3</span>
                                          </div>
                                          <div className="menu-content">
                                              <h5 className="title">Get On Boarded</h5>
                                              <p>
                                                  Portfolio Creation Begins, Welcome to the Vallum Family!
                                              </p>
                                          </div>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        {/* How-Section */}

        {/* Readings Section*/}
          <section className="section-light-grey readings-section padding-top padding-bottom bg_img" id="readings">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10">
                  <div className="section-header">
                    <h2 className="title">Vallum's Weekend Reading</h2>
                    <p className="mw-100">
                      "The best thing a human being can do is to help another human being know more"
                      <br/>
                      <span>- Charlie Munger</span>
                    </p>
                    <a href="javascript:void(0)" className="custom-button" onClick={this.openModal}>Subscribe</a>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12">
                  <div className=""><img src={readings} alt="readings"/>
                  </div>
                </div>
              </div>
              <div className="row reading-block m-0 pb-4">
                <OwlCarousel className='owl-theme readings-slider' items={4} loop margin={2} nav={false} responsiveClass={true} dots={true} autoplay={true} autoplayTimeout={2000} autoplayHoverPause= {true} responsiveClass= {true} responsive={this.state.responsive}  >
                  {this.readingsList()}
                </OwlCarousel>
              </div>
              <div className="row justify-content-center">
                <div className="">
                  <a href="/insight-what-vallum-reads" className="custom-button">View More</a>
                </div>
              </div>
            </div>
          </section>

          { this.state.subscribeModalOpen ? 
            <Modal isOpen={this.state.subscribeModalOpen} onRequestClose={this.closeModal} style={customStyles} contentLabel="Subscribe Modal">
              <div className="modal-content">
              <div className="bg-shape bg_img">
                  <a className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                    <i className="fa fa-plus-circle"></i>
                  </a>
               
                <div className="modal-body">
                <div className="d-flex justify-content-center pt-5 pb-5 mb-2">
                <div className="newsletter-icon"><img src={newsletterIcon} alt="newsletterIcon" width="120" /></div>  
                  <div class="col-md-7">
                    <div className="text-center pb-4">
                      {/*<div className="newsletter-logo mb-3"><img src={logo} alt="logo" width="200" /></div>*/}
                      <h4 className="modal-title" id="exampleModalLongTitle text-uppercase">VALLUM READINGS</h4>
                      <div className="sub-title">Subscribe Now</div>
                    </div>
                  <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                    ref={this.formRef}
                    immediate={this.state.immediate}
                    setFocusOnError={this.state.setFocusOnError}
                    defaultErrorMessage={{ required: "Please enter something." }} >
                    <div className="form-group">
                      <TextInput name="subscribeEmail" id="subscribeEmail" type="email" placeholder="Please enter your email id*"
                        validator={(value) => validator.isEmail(value) && !validator.contains(value, '+')} class=""
                        maxLength={100}
                        errorMessage={{ validator: "Please enter a valid email" }}
                        value={this.state.subscribeEmail}
                        onChange={this.handleChange}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">Subscribe Now</button>
                  </ValidationForm>
                </div></div>
                </div>
              </div></div>
            </Modal>
          : null}
        {/* Readings Section*/}

        {/* Information Request Section*/}
          <section className="information-request-section padding-top bg_img" id="information-request">
            <div className="newslater-section">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <InformationRequest />
                  </div>
                </div>
              </div>
            </div>
          </section>
        {/* Information Request Section*/}

        {/* Check Section */}
          <section className="call-section call-overlay">
            <div className="container">
              <div className="row align-items-center justify-content-lg-center">
                <div className="col-md-12 col-lg-8 text-center ">
                  <div className="">
                    <h6 className="cl-white">SEBI PMS Registration no. INP000007650</h6>
                  </div>
                </div>
              </div>
            </div>
          </section>
        {/* Check Section */}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default Home;
